import React, { useEffect, useMemo } from 'react'
import DetailCard from './DetailCard'
import {
    AutoAwesome,
    CheckCircleOutlineOutlined,
    ErrorOutlineOutlined,
    RefreshOutlined,
    WarningAmberOutlined,
    HourglassEmptyOutlined,
    InfoOutlined
} from '@mui/icons-material'
import { Button, Chip, Grid, Stack, Tooltip, Typography, CircularProgress, Box } from '@mui/material'
import { grey } from '@mui/material/colors'
import { deepPurple } from '@mui/material/colors'
import { theme } from '../Theme'
import { useGenerateDemandScoreQuery, useLazyGenerateDemandScoreQuery } from '../services/demandScore/demandScoreApi'
import { useParams, Link } from 'react-router-dom'
import { useDispatch, useSelector } from 'react-redux'
import { setToast } from '../redux/slices/globalToastSlice'

const IndividualScores = ({ insights }) => {
    // Get medicals and dateOfLoss for billBeforeDOL calculation
    const { medicals } = useSelector((state) => state.Medicals);
    const { documentData } = useSelector((state) => state.Document);
    const dateOfLoss = documentData?.claimInfo?.dateOfLoss || null;

    // Calculate if there are any bills before DOL
    const hasBillBeforeDOL = useMemo(() => {
        const hasMedicals = medicals?.medicalTreatments && medicals.medicalTreatments.length > 0;
        if (!hasMedicals || !dateOfLoss) return null;
        const dolDate = new Date(dateOfLoss);

        return medicals.medicalTreatments.some(treatment => {
            if (!treatment.treatmentDate) return false;
            const treatmentDate = new Date(treatment.treatmentDate);
            return treatmentDate < dolDate;
        });
    }, [medicals, dateOfLoss]);


    return (
        insights.map((insight, index) => {
            const mappedName = getScoreNameMapping(insight.score_name);

            // Special handling for billBeforeDOL
            let mappedScore;
            if (mappedName === 'billBeforeDOL') {
                if (hasBillBeforeDOL === null) {
                    // If we can't calculate (no medical treatments or no DOL), show as pending
                    mappedScore = 'pending';
                } else {
                    mappedScore = hasBillBeforeDOL ? 'bad' : 'good';
                }
            } else {
                // For other insights, use the API's score mapping
                mappedScore = getScoreValueMapping(insight.score);
            }

            const scoreInfo = scoreLanguage[mappedName];
            const colorInfo = colorMapping[mappedScore];

            if (!scoreInfo || !colorInfo) return null;

            // Determine the display label
            let displayLabel;
            if (mappedName === 'billBeforeDOL') {
                displayLabel = mappedScore === null ? 'Pending' : scoreInfo[mappedScore];
            } else if (mappedScore === 'error') {
                displayLabel = 'Error';
            } else {
                displayLabel = insight.description || scoreInfo[mappedScore];
            }

            // Determine the tooltip content
            let tooltipContent;
            if (mappedScore === 'pending') {
                tooltipContent = scoreInfo.pendingTooltip;
            } else if (mappedScore === 'error' && insight.score_reason) {
                // For error state, use the reason field as tooltip
                tooltipContent = insight.score_reason;
            } else {
                tooltipContent = scoreInfo.tooltip;
            }

            return (
                <Stack
                    key={index}
                    sx={{
                        flexBasis: "calc(50% - 8px)",
                        flexGrow: "1 1 50%",
                        minWidth: 175,
                        maxWidth: { xs: '100%' }
                    }}
                >
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        {scoreInfo.label}
                    </Typography>
                    <Tooltip
                        title={tooltipContent}
                        placement='top'
                        arrow
                    >
                        <Chip
                            label={displayLabel}
                            icon={colorInfo.icon}
                            variant="outlined"
                            sx={{
                                justifyContent: "flex-start",
                                backgroundColor: colorInfo.fill,
                                borderColor: colorInfo.border,
                                color: colorInfo.text,
                                py: .5,
                                "& .MuiChip-icon": {
                                    color: colorInfo.border
                                }
                            }}
                        />
                    </Tooltip>
                </Stack>
            );
        })
    );
};

// Loading scores component with spinners
const LoadingScores = () => {

    return (
        scoreTypesInOrder.map((scoreType, index) => {
            const scoreInfo = scoreLanguage[scoreType];

            return (
                <Stack
                    key={index}
                    sx={{
                        flexBasis: "calc(50% - 8px)",
                        flexGrow: "1 1 50%",
                        minWidth: 175,
                        maxWidth: { xs: '100%' }
                    }}
                >
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        {scoreInfo.label}
                    </Typography>
                    <Chip
                        label="Loading"
                        variant="outlined"
                        icon={
                            <CircularProgress
                                size={16}
                                thickness={5}
                                sx={{ color: grey[500] }}
                            />
                        }
                        sx={{
                            justifyContent: "flex-start",
                            backgroundColor: grey[100],
                            borderColor: grey[400],
                            color: grey[700],
                            py: .5,
                            "& .MuiChip-icon": {
                                color: grey[500],
                                marginLeft: 1.5,
                                marginRight: -0.5
                            }
                        }}
                    />
                </Stack>
            );
        })
    );
};

const PendingScores = () => {

    return (
        scoreTypesInOrder.map((scoreType, index) => {
            const scoreInfo = scoreLanguage[scoreType];

            return (
                <Stack
                    key={index}
                    sx={{
                        flexBasis: "calc(50% - 8px)",
                        flexGrow: "1 1 50%",
                        minWidth: 175,
                        maxWidth: { xs: '100%' }
                    }}
                >
                    <Typography variant="caption" color={theme.palette.text.secondary}>
                        {scoreInfo.label}
                    </Typography>
                    <Tooltip
                        title={scoreInfo.pendingTooltip}
                        placement='top'
                        arrow
                    >
                        <Chip
                            label="Pending"
                            icon={<HourglassEmptyOutlined />}
                            variant="outlined"
                            sx={{
                                justifyContent: "flex-start",
                                backgroundColor: "#E3F2FD",
                                borderColor: theme.palette.info.main,
                                color: theme.palette.text.secondary,
                                py: .5,
                                "& .MuiChip-icon": {
                                    color: theme.palette.info.main
                                }
                            }}
                        />
                    </Tooltip>
                </Stack>
            );
        })
    );
};

// New error display component
const ErrorDisplay = ({ onRefresh }) => {
    return (
        <Box sx={{
            width: '100%',
            display: 'flex',
            flexDirection: 'column',
            alignItems: 'center',
            justifyContent: 'center',
            py: 2
        }}>
            <Typography
                color="error"
                sx={{
                    fontSize: '1.1rem',
                    fontWeight: 500,
                    mb: 1,
                    display: 'flex',
                    alignItems: 'center'
                }}
            >
                Update failed - please try again <RefreshOutlined sx={{ ml: 1, cursor: 'pointer' }} onClick={onRefresh} />
            </Typography>
            <Typography
                color="text.secondary"
                sx={{
                    textAlign: 'center',
                    maxWidth: '80%'
                }}
            >
                If the problem continues, please contact
                <br />
                <Link
                    href="mailto:helpdesk@precedent.com"
                    color="primary"
                    underline="hover"
                >
                    helpdesk@precedent.com
                </Link>
            </Typography>
        </Box>
    );
};

const DemandScoreCard = () => {
    const { documentId } = useParams();
    const dispatch = useDispatch();

    // Get the demand score data from the slice that has a matcher to funnel the query and the lazy query (manual refresh) into a shared state
    const { demandScoreData, demandScoreDataLoading, demandScoreDataError } = useSelector((state) => state.DemandScore);
    const currentVersion = demandScoreData?.version || 1;

    // Onload use 1 as the version to get the most recently generated demand score
    const { error: dsError } = useGenerateDemandScoreQuery({ documentId, version: 1 });

    // Use the lazy query to generate the demand score on refresh CTA
    const [generateDemandScore, { isError: refreshError }] = useLazyGenerateDemandScoreQuery();

    const formatCurrentDate = () => {
        // Convert Unix timestamp (seconds) to milliseconds if it exists
        const timestampMs = demandScoreData?.createdTs
            ? demandScoreData.createdTs * 1000
            : Date.now();

        const now = new Date(timestampMs);
        const date = now.toLocaleDateString('en-US', { month: '2-digit', day: '2-digit', year: 'numeric' });
        const time = now.toLocaleTimeString('en-US', { hour: '2-digit', minute: '2-digit', hour12: true });

        return `${date} ${time}`;
    };

    const timestamp = formatCurrentDate();

    const errorMessage = demandScoreDataError?.message || dsError?.message || 'Error loading demand score';
    // Get the new version of the demand score on refresh CTA click
    const handleRefresh = async () => {
        try {
            dispatch(setToast({
                isOpen: true,
                message: "Refreshing demand score...",
                severity: 'info'
            }));
            await generateDemandScore({ documentId, version: currentVersion + 1 }).unwrap();
        } catch (error) {
            dispatch(setToast({
                isOpen: true,
                message: errorMessage,
                severity: 'error'
            }));
        }
    };

    useEffect(() => {
        if (demandScoreDataError) {
            dispatch(setToast({
                isOpen: true,
                message: errorMessage,
                severity: 'error'
            }));
        }
    }, [demandScoreDataError, dispatch]);

    const isLoading = demandScoreDataLoading;
    const hasError = demandScoreDataError || refreshError;

    return (
        <Grid item
            sx={{ flex: "2", minWidth: "350px", maxWidth: { xs: '100%', lg: '49%' }, }}
        >
            <DetailCard
                icon={<AutoAwesome sx={{ color: deepPurple[900] }} />}
                label="Case intelligence"
                action={
                    <Tooltip title={demandScoreDataLoading ? "Loading..." : "Refresh case intelligence and highlight tags"} arrow>
                        <Button
                            variant='text'
                            color='primary'
                            size='small'
                            onClick={handleRefresh}
                            disabled={isLoading}
                            endIcon={<RefreshOutlined sx={{
                                animation: isLoading ? 'spin 1s linear infinite' : 'none',
                                '@keyframes spin': {
                                    '0%': { transform: 'rotate(0deg)', },
                                    '100%': { transform: 'rotate(360deg)' }
                                }
                            }} />}
                            sx={{ mr: 1 }}
                        >
                            {timestamp}
                        </Button>
                    </Tooltip>
                }
            >
                {hasError ? (
                    <ErrorDisplay onRefresh={handleRefresh} />
                ) : (
                    <Stack
                        direction="row"
                        flexWrap="wrap"
                        justifyContent={"space-between"}
                        sx={{
                            rowGap: .5,
                            columnGap: 1,
                            alignItems: "flex-start",
                        }}
                    >
                        {isLoading ? (
                            <LoadingScores />
                        ) : demandScoreData?.insights && demandScoreData.insights.length > 0 ? (
                            <IndividualScores insights={demandScoreData.insights} />
                        ) : (
                            <PendingScores />
                        )}
                    </Stack>
                )}
            </DetailCard>
        </Grid>
    )
}

export default DemandScoreCard



// Utils
const scoreLanguage = {
    billCompleteness: {
        label: 'Bill completeness',
        tooltip: 'AI evaluates if medical records have matching bills. Medium or low confidence scores should prompt further investigation to ensure all bills are present and correct.',
        pendingTooltip: 'AI evaluates if medical records have matching bills. Medium or low confidence scores should prompt further investigation to ensure all bills are present and correct. To see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Low confidence',
        med: 'Med confidence',
        good: 'High confidence',
        pending: 'Pending'
    },
    billContinuity: {
        label: 'Bill continuity',
        tooltip: 'AI checks for consistent billing from injury to demand submission. Scores indicating treatment delays or gaps suggest attorneys prepare for potential disputes about treatment timelines.',
        pendingTooltip: 'AI checks for consistent billing from injury to demand submission. Scores indicating treatment delays or gaps suggest attorneys prepare for potential disputes about treatment timelines. To see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Gaps identified',
        med: 'Initial treatment delay',
        good: 'No gaps',
        pending: 'Pending'
    },
    demandVelocity: {
        label: 'Demand velocity',
        tooltip: 'AI calculates the case complexity, total demand processing time, and the relative speed from last treatment date to demand submission. Average or slow ratings highlight cases submitted later than industry norms, offering an opportunity to refine processes for faster resolution.',
        pendingTooltip: 'AI calculates the case complexity, total demand processing time, and the relative speed from last treatment date to demand submission. Average or slow ratings highlight cases submitted later than industry norms, offering an opportunity to refine processes for faster resolution. To see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Slow',
        med: 'Average',
        good: 'Fast',
        pending: 'Pending'
    },
    earlyBillSeverity: {
        label: 'Early bill severity',
        tooltip: 'AI assesses the severity of initial medical treatments post-injury. Average or low scores suggest client sought limited or conservative care during the acute phase.',
        pendingTooltip: 'AI assesses the severity of initial medical treatments post-injury. Average or low scores suggest client sought limited or conservative care during the acute phase. To see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Low',
        med: 'Average',
        good: 'High',
        pending: 'Pending'
    },
    billBeforeDOL: {
        label: 'Bill before DOL',
        tooltip: 'AI flags billing charges before the reported injury date.',
        pendingTooltip: 'AI flags billing charges before the reported injury date. To see scores, upload medical bills and wait for AI processing to complete.',
        bad: 'Detected',
        good: 'None',
        pending: 'Pending'
    },
};

// Map API score values to our internal score types
const getScoreValueMapping = (apiValue) => {
    if (!apiValue) return 'pending';

    const normalizedValue = String(apiValue).toLowerCase();

    const mappings = {
        'good': 'good',
        'bad': 'bad',
        'neutral': 'med',
        'false': 'bad',
        'true': 'good',
        'medium': 'med',
        'n/a': 'pending',
        'error': 'error'
    };

    return mappings[normalizedValue] || 'pending';
};


// Map API score_name values to our internal names
const getScoreNameMapping = (apiScoreName) => {
    if (!apiScoreName) return null;

    const normalizedName = String(apiScoreName).toUpperCase();

    const mappings = {
        'BILL_BEFORE_DOL': 'billBeforeDOL',
        'BILL_COMPLETENESS': 'billCompleteness',
        'DEMAND_VELOCITY': 'demandVelocity',
        'EARLY_BILLING_DENSITY': 'earlyBillSeverity',
        'TREATMENT_CONTINUITY': 'billContinuity'
    };

    return mappings[normalizedName] || null;
};

// The expected order of display
const scoreTypesInOrder = [
    'billBeforeDOL',
    'billCompleteness',
    'billContinuity',
    'demandVelocity',
    'earlyBillSeverity'
];

const colorMapping = {
    bad: {
        text: theme.palette.text.primary,
        border: '#E57C45',       // Orange border 
        fill: '#FFF4E5',         // Light orange background
        icon: <WarningAmberOutlined />
    },
    med: {
        text: theme.palette.text.primary,
        border: '#1976D2',       // Blue border
        fill: '#E3F2FD',         // Light blue background
        icon: <InfoOutlined />
    },
    good: {
        text: theme.palette.text.primary,
        border: '#4CAF50',       // Green border
        fill: '#EDF7ED',         // Light green background 
        icon: <CheckCircleOutlineOutlined />
    },
    pending: {
        text: theme.palette.text.secondary,
        border: '#9C27B0',       // Purple border
        fill: '#F3E5F5',         // Light purple background
        icon: <HourglassEmptyOutlined />
    },
    error: {
        text: theme.palette.text.primary,
        border: '#D32F2F',       // Red border
        fill: '#FDEDED',         // Light red background
        icon: <ErrorOutlineOutlined />
    },
    loading: {
        text: theme.palette.text.secondary,
        border: grey[400],
        fill: grey[100],
        icon: null               // Will be replaced with CircularProgress
    }
};