export default {
    "REGION": "us-east-1",
    "USER_POOL_ID": "us-east-1_1o83bMws3",
    "USER_POOL_APP_CLIENT_ID": "1b93lo1pqqm9f7o5ck060ggn5h",
    "ROOT_API_URL": "https://33yhvxkmre.execute-api.us-east-1.amazonaws.com/dephh-dev-documents-api-prod",
    "MFA_ISSUER": "Precedent-dephh-dev",
    "S3_DEPLOYMENT_BUCKET": "dephh-dev-precedent-exchange-document-spa",
    "RUM_IDENTITY_POOL_ID": "us-east-1:e4ca9da5-d506-4b6a-8d96-cb14c53f381e",
    "RUM_APP_MONITOR_ID": "158d5b73-4a96-4b08-84a4-3819de4023bf",
    "ENV_STAGE": "dev-dephh"
}
